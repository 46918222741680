<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="uil uil-users-alt display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Reseller account list and account creation</small>
                </div>
              </div>
              <div class="ms-auto">
                <button class="btn btn-outline-info fw-medium me-1" @click="resetResellerPage()" v-if="selectedReseller.account_db_code !=='all' && selectedReseller.account_db_code !=='-1' && selectedReseller.account_db_code !==currentAccount.account_db_code">
                  <i class="uil uil-home-alt font-size-16"></i>
                </button>
                <button class="btn btn-outline-info fw-medium me-1" @click="goBack()" v-if="selectedReseller.account_db_code !=='all' && selectedReseller.account_db_code !=='-1' && selectedReseller.account_db_code !==currentAccount.account_db_code" :disabled="disabledGoBack">
                  <i class="mdi mdi-arrow-left font-size-16"></i>
                </button>
                <button class="btn btn-outline-info fw-medium" v-if="create_modify_permission" @click="createReseller">
                  <i class="uil uil-plus font-size-16 me-1"></i> Add Reseller Account
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="rounded-4 p-3 p-lg-4 mb-4 custom_top_data_bar">
                <div class="text-center" v-if="loading">
                <div
                  class="spinner-border text-secondary my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div v-else>
                <h4 class="font-size-18 text-white mb-2" v-if="account_type=='admin'"> Account Info</h4>
                <div class="row mb-2" >
                  <div class="col-12 col-lg-4" v-if="account_type=='admin'">
                    <multiselect v-model="selectedReseller" :options="reseller" label="account_name" :class="{'mb-2':selectedReseller.account_db_code!=='all' && selectedReseller.account_db_code!=='-1'}" placeholder="Select Reseller" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="changeUrl()">
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'">Z</span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>

                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                            {{ props.option.account_name }}
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'">Z</span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>
                            
                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                          {{ props.option.account_name }} 
                        </span>
                      </template>
                      <span slot="noResult">Oops! No reseller found. </span>
                    </multiselect>
                  </div>
                </div>
                <div class="row" v-if="selectedReseller.account_db_code!=='all' && selectedReseller.account_db_code!=='-1'">

                  <div class="col-12 col-lg col-md-4 mb-3 mb-lg-0">
                     <!-- mobile -->
                     <div class="d-flex align-items-center">
                        <div><i class="uil uil-user me-2 text-primary display-6"></i></div>
                        <div> 
                          <div class="font-size-16 fw-medium mb-0 lh-1">Account</div>
                          <div class="text-purple">
                            #{{selectedReseller.id}} {{selectedReseller.account_name}} 
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg col-md-6 mb-3 mb-lg-0" v-if="account_type=='admin'">
                     <!-- mobile -->
                     <div class="d-flex align-items-center">
                        <div><i class="uil uil-sitemap me-2 text-primary display-6"></i> </div>
                        <div> 
                          <div class="font-size-16 fw-medium mb-0 lh-1">Reseller Upline</div>
                          <div class="text-purple mb-0 font-size-14">
                            <span v-if="selectedReseller.account_username !==defaultName">
                              <span v-if="account_type=='reseller' &&  selectedReseller.reseller_parent[selectedReseller.reseller_parent.length - 1].name==defaultName "> {{ selectedReseller.reseller_parent[selectedReseller.reseller_parent.length - 1].name }}</span>
                              <span v-else @click="goBack()" class="cursor-pointer"> {{ selectedReseller.reseller_parent[selectedReseller.reseller_parent.length - 1].name }}</span>
                            </span>
                            <span v-else>
                              -
                            </span>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg col-md-4 mb-3 mb-lg-0">
                       <!-- mobile -->
                      <div class="d-flex align-items-center cursor-pointer" @click="showContract(selectedReseller)">
                        <div><i class="uil uil-usd-circle  me-2 text-primary display-6"></i> </div>
                        <div> 
                          <div class="font-size-16 fw-medium mb-0 lh-1">Contracts</div>
                          <a class="text-purple d-block font-size-14 lh-1" href="javascript:void(0)">View Contracts <i class="uil uil-arrow-right font-size-16"></i>  </a>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg col-md-4 mb-3 mb-lg-0">
                    <!-- mobile -->
                    <div class="d-flex align-items-center cursor-pointer" @click="showBalance(selectedReseller)">
                      <div><i class="uil uil-wallet me-2 text-primary display-6"></i> </div>
                      <div> 
                        <div class="font-size-16 fw-medium mb-0 lh-1">Current Balance</div>
                        <a class="text-purple d-block font-size-14 lh-1" href="javascript:void(0)">View Balance <i class="uil uil-arrow-right font-size-16"></i>  </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg col-md-4 mb-3 mb-lg-0">
                      <!-- mobile -->
                      <div class="d-flex align-items-center">
                        <div><i class="uil uil-users-alt me-2 text-primary display-6"></i> </div>
                        <div> 
                          <div class="font-size-16 fw-medium mb-0 lh-1">Merchants</div>
                          <router-link v-if="selectedReseller.account_db_code !=='-1'" :to="{name:'merchants with reseller id', params:{id: selectedReseller.account_db_code}}" class="text-purple font-size-14 lh-1">
                          View Merchants <i class="uil uil-arrow-right font-size-16"></i> 
                          </router-link>
                          <router-link v-else :to="{name:'merchants', params:{id: selectedReseller.account_db_code}}" class="text-purple font-size-14 lh-1">
                         View Merchants <i class="uil uil-arrow-right font-size-16"></i> 
                          </router-link>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="font-size-18 mb-3"><i class="uil uil-list-ul text-primary me-1"></i> 
              <span v-if="selectedReseller.account_db_code !=='all'">{{ selectedReseller.account_name }} - </span>
              <span v-else>All </span>
               Resellers List ({{ returnData.length }})</h4>

              <div class="col-12 col-md-6 mb-2">
                <div class="d-flex mb-3">
                <div class="app-search2 position-relative col">
                  <input
                    type="text"
                    placeholder="Search Reseller"
                    class="form-control mb-0"
                    v-model.trim="searchReseller"
                    autocomplete="no"
                    @keydown.enter="handlePageChange(1)"
                    @search="handlePageChange(1)"
                  />
                  <span class="uil-search text-primary"></span>
                </div>
                <button
                  class="btn btn-light rounded ms-1"
                  type="button"
                  :disabled="disabled"
                  @click="searchReseller='', handlePageChange(1)"
                >
                  <span>Clear</span>
                </button>
              </div>
                 
            
            </div>
            <div class="table-responsive text-nowrap font-size-14 position-relative">
              <!-- <div class="position-relative">
                <b-button id="tooltip-button-interactive">My tooltip is interactive</b-button>
                <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
              </div> -->
              <table id="freeze" class="table mt-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                <thead class="text-uppercase">
                  <tr>
                    <th>No.</th>
                    <th>Reseller<br>Reseller Code</th>
                    <th>Reseller Username<br>Code for support </th>
                    <th>Total Reseller<br>Total Merchant</th>
                    <th>Reseller Contract<br>Current Balance</th>
                    <th>Status<br>Login Block</th>
                    <th class="text-center" v-if="(currentRouteName=='resellers' || account_type=='admin' || $route.params.id == currentAccount.account_db_code) && create_modify_permission">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td :colspan="(currentRouteName=='resellers' || account_type=='admin' || $route.params.id == currentAccount.account_db_code) && create_modify_permission ? 7 : 6" height="300" class="text-center text-muted">
                      <i class="uil uil-user me-1"></i> No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td :colspan="(currentRouteName=='resellers' || account_type=='admin' || $route.params.id == currentAccount.account_db_code) && create_modify_permission ? 7 : 6" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  <tr v-for="(value, index) in returnData" :key="index" >
                    <td>
                      {{ currentPage * perPage - perPage + index + 1 }}
                    </td> 
                    <td>
                     <span class="text-primary cursor-pointer" @click="selectedReseller=value, changeUrl()">#{{ value.id }} {{ value.account_name }}</span>
                     <div>{{ value.reseller_code }}</div>
                    </td>
                    <td>
                     {{value.account_username}}
                     <div>  {{ value.code_for_support_skype || value.reseller_code}}</div>
                    </td> 
                    <td>
                      <span class="text-primary cursor-pointer" @click="searchReseller='',selectedReseller=value, changeUrl()">
                        <i class="uil uil-users-alt"></i> View Resellers ({{value.reseller_count || 0}})</span>
                        <div>
                          <router-link v-if="value.account_db_code !=='-1'" :to="{name:'merchants with reseller id', params:{id: value.account_db_code}}" class="text-primary font-size-14 lh-1">
                          <i class="uil uil-users-alt"></i> View Merchants ({{value.merchant_count}})
                          </router-link>
                          <router-link v-else :to="{name:'merchants', params:{id: value.account_db_code}}" class="text-primary font-size-14 lh-1">
                            <i class="uil uil-users-alt"></i> View Merchants ({{value.merchant_count}})
                          </router-link>
                        </div>
                    </td>  
                    <td>
                      <a href="javascript:void(0)" v-b-tooltip.hover title="" class="text-primary" @click="showContract(value)">
                        <i class="uil uil-usd-circle"></i> View Contracts
                      </a>
                      <div>
                        <a href="javascript:void(0)" v-b-tooltip.hover title="" class="text-primary" @click="showBalance(value)">
                          <i class="uil uil-wallet"></i> View Balance
                        </a>
                      </div>
                    </td>  
                    <td>
                      <span v-if="value.status=='inactive'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                     <span v-else-if="value.status=='active'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                     <div>
                      <span v-if="value.login_block=='1'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">
                      Blocked
                      </span>
                      <span v-else>Unblock</span>
                    </div>
                    </td> 
                    <td class="text-center" v-if="(currentRouteName=='resellers' || account_type=='admin' || $route.params.id == currentAccount.account_db_code) && create_modify_permission">

                      <button v-if="account_type=='admin' && value.reseller_level==-1" class="btn btn-outline-info px-2 py-1 font-size-15 me-1 rounded" @click="specialEditReseller(value)">
                      <i class="uil uil-star"></i></button>

                      <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="editReseller(value)">
                      <i class="uil uil-edit"></i></button>
                    </td>  
                    
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !loading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <reseller ref="modalFunc" :data="{'accessUsername':accessUsername, 'assessToken':accessToken, 'updateData':updateData}" @callParentFunction="go2theResellerPageAfterAdd" @callParentFunction2="handlePageChange(currentPage)" />
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import {required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import reseller from "@/views/pages/app/modal/createEditReseller";

export const equal2A = (value) => {
  return value !== 'YOUR_API_ID';
}
export const equal2B = (value) => {
  return value !== 'YOUR_API_HASH';
}
const CancelToken = axios.CancelToken;
let cancel;
/**
 * Resellers page
 */
const PageTitle = "Resellers"
const ResellerDefault =
{
  "account_db_code": "-1",
  "account_name": "Current Account",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
const AllAccountDefault =
{
  "id": "",
  "account_username": "",
  "account_db_code": "all",
  "account_name": "All Accounts",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    Multiselect,
    reseller
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      defaultName: appConfig.defaultAccountName,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      updateData:{},
      perPage: 20,
      totalData: 0,
      search_value:"",
      searchReseller:"",
      returnData: [],
      submitted:false,
      loading:false,
      disable_date:false,
      disabled:false,
      resellerId: "",
      buttonLoading:[false,false,false],
      currentRouteName: "",
      selectedReseller: ResellerDefault,
      reseller:[ResellerDefault],
      disabledGoBack:false,
      currentAccount:{},
      create_modify_permission:false
    };
  },
  defaultName() {
    return this.appConfig.pageTitle.toUpperCase();
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A
      },
      apiHash: {
        required,
        equal2B
      },
    }
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload()
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.getReseller()
    this.currentRouteName = this.$route.name
  }, 
  created(){
   
  },
  methods:{
    go2theResellerPageAfterAdd(value){
      var currentID = value;
      this.reseller.forEach(element => {
          if (element.account_db_code==currentID){
            console.log(element)
            this.selectedReseller = element
          }
      });
      if (this.$route.params.id !== currentID){
        this.changeUrl();
      }else{
        this.handlePageChange(this.currentPage)
      }
      this.updateResellerList();
    },
    resetResellerPage(){
      this.selectedReseller = (this.account_type==='admin') ? AllAccountDefault : this.currentAccount;
      this.changeUrl();
    },
    goBack(){
      var parentId = this.selectedReseller.reseller_parent[this.selectedReseller.reseller_parent.length - 1].account_db_code;
      this.reseller.forEach(element => {
          if (element.account_db_code==parentId){
            console.log(element)
            this.selectedReseller = element
          }
      });
      this.disabledGoBack =true
      if (this.$route.params.id !==this.selectedReseller.account_db_code){
        this.changeUrl();
        this.disabledGoBack = false
      }
    },
    createReseller(){
      setTimeout(() =>  this.$refs.modalFunc.showModal(),100)
    },
    editReseller(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showEditModal(),100)
    },
    specialEditReseller(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showSpecialEditModal(),100)
    },
    showContract(value){
      this.updateData=value;
      this.updateData.current_account_db_code=this.currentAccount.account_db_code;
      setTimeout(() =>  this.$refs.modalFunc.showViewModal('contract'),100)
    },
    showBalance(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showViewModal('wallet'),100)
    },
    changeUrl(){
      console.log('changeUrl')
      this.disabledGoBack = false
      if(this.selectedReseller.account_db_code==-1 || this.selectedReseller.account_db_code=='all'){
        this.$router.replace({ name: 'resellers' })
        this.currentRouteName = 'resellers'
        this.handlePageChange(1)
      }else{
        this.$router.replace({ name: 'resellers with reseller id', params:{id:this.selectedReseller.account_db_code}})
        this.currentRouteName = 'resellers with reseller id'
        this.handlePageChange(1)
      }
    },
    convertCurrencyFormat(value,show00) {
      if (show00==true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      }else{
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
      }
    },
    updateResellerList() {
      console.log("getReseller")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
      bodyFormData.append("searchReseller", "")
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.reseller = [ResellerDefault]
          this.selectedReseller = ResellerDefault
          if (this.account_type=='admin'){
            this.reseller=[AllAccountDefault]
            this.selectedReseller = this.reseller[0]
          }
          if (this.account_type=='reseller'){
            this.reseller=[resData.current_account]
            this.selectedReseller = this.reseller[0]
            this.currentAccount = resData.current_account
          }
          resData.data.forEach((element) => {
            this.reseller.push(element);
            if (element.account_db_code == this.$route.params.id){
              this.selectedReseller = element
            }
          });

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.loading = false;
          this.disabled = false
        }
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    getReseller() {
      if(cancel !== undefined) cancel();
      console.log("getReseller")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
      bodyFormData.append("searchReseller", "")
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        cancelToken: new CancelToken(c => cancel = c)
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.reseller = [ResellerDefault]
          this.selectedReseller = ResellerDefault
          if (this.account_type=='admin'){
            this.reseller=[AllAccountDefault]
            this.selectedReseller = this.reseller[0]
          }
          if (this.account_type=='reseller'){
            this.reseller=[resData.current_account]
            this.selectedReseller = this.reseller[0]
            this.currentAccount = resData.current_account
          }
          resData.data.forEach((element) => {
            this.reseller.push(element);
            if (element.account_db_code == this.$route.params.id){
              this.selectedReseller = element
            }
          });
          this.handlePageChange(1)

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.loading = false;
          this.disabled = false
        }
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.search_value);
        //this.exportData(1)
    },
    async getData(pages){
        if(cancel !== undefined) cancel();
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        this.disable_date = true;
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code );
        bodyFormData.append("searchReseller", this.searchReseller)
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          cancelToken: new CancelToken(c => cancel = c)
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            if (resData.status == 200) {
              this.totalData = resData.total
              var responseData = resData.data;

              responseData.forEach(element => {
                if (element.account_username!==this.defaultName){
                  this.returnData.push(element);
                }
              });

              if (this.selectedReseller.account_db_code==-1 && this.selectedReseller.account_db_code=='all'){
                //if user type wrongly no match with reseller list xxx/reseller/abc123
                if (this.$route.name!=='resellers'){
                  //this.$router.replace({ name: 'resellers' })
                  this.currentRouteName = 'resellers'
                }
              }
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          console.log(data.account_type)
          const permission = data.modules.filter(e => e.name==='account_management')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
            console.log(this.create_modify_permission)
          } else {
            console.log('Module with name "account_management" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>
<style>
.currency_width{
  display: inline-block !important;
  width: 40px !important;
}
</style>
